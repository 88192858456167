import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import {
  Box,
  Typography,
  Container,
  Grid,
  Table,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import Diver from "../../static/Imagen/diver.png"
import Crediticia from "../../static/Imagen/abc.png"
import ImaMonto from "../../static/Imagen/monto.png"
import Evaluacion from "../../static/Imagen/evaluacion.png"

const useStyles = makeStyles(theme => ({
  root: {},
  containerPart: {
    [theme.breakpoints.down("md")]: {
      marginTop: "6em",
    },
    marginTop: "10em",
  },
  boxImg: {
    display: "flex",
    justifyContent: "center",
    margin: "auto",
  },
  linksStyles: {
    textDecoration: "none",
    color: theme.palette.common.black,
    fontWeight: 500,
    "&:hover": {
      color: "#291C64",
    },
  },
  media: {
    height: 170,
  },
  liStyle: {
    textAlign: "center",
  },
  liStyleC: {
    textAlign: "justify",
  },
}))

export default function Risks() {
  const classes = useStyles()
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>CFL</title>
      </Helmet>
      <Container className={classes.containerPart}>
        <Box maxWidth="80%" m="auto">
          <Box mt={4} py={2}>
            <Typography variant="h2" component="h2" align="center" gutterBottom>
              <b>Divulgación de riesgos</b>
            </Typography>
          </Box>
          <Box>
            <div className={classes.root}>
              <Typography variant="h4" component="h4">
                <b>Conozca los riesgos asociados a su inversión.</b>
              </Typography>
              <Typography
                variant="body1"
                gutterBottom
                inline
                className={classes.liStyleC}
              >
                Los retiros son gratuitos y, por lo tanto, la venta de préstamos
                o partes de ellos es gratuita, pero está limitada por la
                liquidez del mercado secundario.
              </Typography>
              <Typography
                variant="body1"
                gutterBottom
                inline
                className={classes.liStyleC}
              >
                El tratamiento fiscal depende de sus circunstancias y puede
                estar sujeto a cambios en el futuro.
              </Typography>
              <Typography
                variant="body1"
                gutterBottom
                inline
                className={classes.liStyleC}
              >
                Concretamente, debe de conocer lo siguiente:
                <ol type="a">
                  <li className={classes.liStyleC}>
                    La existencia de riesgos tecnológicos, cibernéticos y de
                    fraude inherentes a las operaciones que realice a través de
                    la plataforma.
                  </li>
                  <br />
                  <li className={classes.liStyleC}>
                    La existencia de riesgos estratégicos, operacionales,
                    financieros, personales y regulatorios inherentes a las
                    operaciones que realice a través de la plataforma.{" "}
                  </li>
                  <br />
                  <li className={classes.liStyleC}>
                    CFL no se hace responsable en caso de pérdida de llaves
                    digitales y/o contraseñas por su parte.
                  </li>
                  <br />
                  <li className={classes.liStyleC}>
                    CFL no se hace responsable en el caso de accesos
                    malintencionados consecuencia de falta de custodia o
                    diligencia con las propias llaves de acceso y/o contraseñas
                    por su parte.
                  </li>
                  <br />
                  <li className={classes.liStyleC}>
                    Responsabilidad, por cualquier daño o perjuicio, directo o
                    indirecto, incluyendo, sin limitación, todas las pérdidas,
                    costes, gastos, pérdida de beneficios, pérdida de ingresos o
                    expectativas de ganancias esperadas como consecuencia de la
                    falta en el servicio.
                  </li>
                  <br />
                  <li className={classes.liStyleC}>
                    Responsabilidad para el caso de retrasos, dificultades o
                    condiciones que afectan negativamente la transmisión o la
                    ejecución de órdenes.
                  </li>
                  <br />
                </ol>
              </Typography>
              <Typography
                variant="body1"
                gutterBottom
                inline
                className={classes.liStyleC}
              >
                Ni el Gobierno Federal ni las Entidades de la Administración
                Pública Paraestatal podrán responsabilizarse o garantizar los
                recursos de los usuarios que sean utilizados en las operaciones
                que celebren con CFL o frente a otros, ni asumir alguna
                responsabilidad por las obligaciones contraídas por CFL o por
                algún Usuario frente a otro, en virtud de las operaciones que
                celebren.
              </Typography>
              <Typography
                variant="body1"
                gutterBottom
                inline
                className={classes.liStyleC}
              >
                Emprendimientos Maussan y González, S.A.P.I., de C.V.,
                Institución de Financiamiento Colectivo ("CFL") es una sociedad
                autorizada para organizarse y operar como Institución de
                Financiamiento Colectivo por la Comisión Nacional Bancaria y de
                Valores, por lo que se encuentra autorizada, regulada y
                supervisada por las Autoridades Financieras. Lo anterior,
                mediante oficio número P176/2021 publicado en el Diario Oficial
                de la Federación el seis de abril de dos mil veintitrés.
              </Typography>
            </div>
          </Box>

          <Box>
            <Box mt={4} py={2}>
              <Typography
                variant="h3"
                component="h2"
                align="center"
                gutterBottom
              >
                <b>
                  Gestión de riesgos: nuestros mecanismos de mitigación de
                  riesgos
                </b>
              </Typography>
            </Box>
            <Box>
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="initial"
              >
                <Grid item xs={12} sm={3}>
                  <Typography>
                    <Grid container>
                      <Box className={classes.boxImg}>
                        <img src={Diver} alt="img" />
                      </Box>
                    </Grid>
                  </Typography>
                  <Typography
                    variant="h6"
                    component="h2"
                    align="center"
                    gutterBottom
                  >
                    Diversificación según Ley Fintech
                  </Typography>
                  <Typography
                    variant="body2"
                    gutterBottom
                    inline
                    className={classes.liStyle}
                  >
                    CFL cumple con lo establecido en el artículo 49 de las
                    Disposiciones de Carácter General aplicables a las
                    Instituciones de Tecnología Financiera diversificación, de
                    acuerdo al artículo XXX, y va más allá, pues aplica los
                    límites más restrictivos independientemente del tipo de
                    solicitante (persona moral o física).
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Typography>
                    <Grid container>
                      <Box className={classes.boxImg}>
                        <img src={Crediticia} alt="img" />
                      </Box>
                    </Grid>
                  </Typography>
                  <Typography
                    variant="h6"
                    component="h2"
                    align="center"
                    gutterBottom
                  >
                    Diversificación por calificación crediticia
                  </Typography>
                  <Typography
                    variant="body2"
                    gutterBottom
                    inline
                    className={classes.liStyle}
                  >
                    Nuestros sistemas internos de asignación de préstamos
                    garantizan la siguiente diversificación en cuanto a scoring
                    crediticio y máximo importe asignado en cada préstamo
                  </Typography>
                  <Typography>
                    <Typography
                      variant="h6"
                      component="h2"
                      align="center"
                      gutterBottom
                    >
                      Normas de Diversificación
                    </Typography>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell>Máximo por préstamo</TableCell>
                          <TableCell>7.50%</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Máximo: calificación A</TableCell>
                          <TableCell>70.00%</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Máximo: calificación B</TableCell>
                          <TableCell>50.00%</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Máximo: calificación C</TableCell>
                          <TableCell>10.00%</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Máximo: calificación D</TableCell>
                          <TableCell>5.00%</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Typography>
                    <Grid container>
                      <Box className={classes.boxImg}>
                        <img src={Evaluacion} alt="img" />
                      </Box>
                    </Grid>
                  </Typography>
                  <Typography
                    variant="h6"
                    component="h2"
                    align="center"
                    gutterBottom
                  >
                    Evaluación de riesgos
                  </Typography>
                  <Typography
                    variant="body2"
                    gutterBottom
                    inline
                    className={classes.liStyle}
                  >
                    Entre otros se realizan los siguientes análisis:
                    <Typography
                      variant="body2"
                      gutterBottom
                      inline
                      className={classes.liStyle}
                    >
                      <li>
                        Diligencia Debida en términos de: Sanciones y PEPs
                        (Personas Políticamente Expuestas)
                      </li>
                      <li>Histórico de extractos bancarios.</li>
                      <li>
                        Consideración de score crediticio externo: “Círculo de
                        Crédito”.
                      </li>
                      <li>
                        Score crediticio propio: Monto del préstamo sobre la
                        garantía (máximo aceptable 50%), Capacidad de pago,
                        propósito del préstamo, Co-garantes, etc.
                      </li>
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Typography>
                    <Grid container>
                      <Box className={classes.boxImg}>
                        <img src={ImaMonto} alt="img" />
                      </Box>
                    </Grid>
                  </Typography>
                  <Typography
                    variant="h6"
                    component="h2"
                    align="center"
                    gutterBottom
                  >
                    Monto del Préstamo sobre la garantía
                  </Typography>
                  <Typography
                    variant="body2"
                    gutterBottom
                    inline
                    className={classes.liStyle}
                  >
                    Todos los financiamientos están garantizados por garantías
                    reales.
                  </Typography>
                  <Typography
                    variant="body2"
                    gutterBottom
                    inline
                    className={classes.liStyle}
                  >
                    Monto del préstamo sobre el valor de la garantía máximo
                    permite en nuestra plataforma asciende al 50%. LTV ≤ 50%
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Container>
    </Layout>
  )
}
